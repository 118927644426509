

export const Message = ({message}) => {
  
  return (
    <div className="rounded-md text-sm px-4 pb-1 font-sans">
      <div className="flex">      
        <span className="whitespace-nowrap w-24 text-gray-500 pr-8">{message.timestamp}</span>
        <span>{message.content}</span>
      </div>      
    </div>
  );
};
