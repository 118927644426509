import React, { useEffect, useRef } from 'react';
import {Message} from './message';
import {Circle} from './circle';
import {useBackend} from "../../../context/BackendContext";
import {Update} from "./update";

const ResponseBox = () => {
  const {
    globalState,
  } = useBackend();

  const messages = globalState.messages;

  const containerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const isUserAtBottom = useRef(true);

  useEffect(() => {
    if (isUserAtBottom.current) {
      scrollToBottom();
    }
  }, [messages]);

  const scrollToBottom = () => {
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
  };

  const handleScroll = () => {
    const container = containerRef.current;
    const isScrolledNearBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 60;

    isUserAtBottom.current = isScrolledNearBottom;
  };

  return (
    <div
      ref={containerRef}
      className="h-full flex-grow overflow-y-auto justify-end"
      onScroll={handleScroll}
    >
      <div className="pt-4 h-full">
        {messages.map((msg, index) => {
          //this mapping does not make sense as it grows - should not do mapping
          //console.log('index is : ' + index);
          if (msg.type === 'message') {
            return (
              <Message key={index} message={{ timestamp: msg.time, content: msg.text }} />
            );
          }

          if (msg.type === 'circle') {
            return (
                <Circle key={index} message={{ timestamp: msg.time, content: msg.text }} />
            );
          }

          if (msg.type === 'update') {
            return (
              <Update key={index} message={{ timestamp: msg.time, update: msg.text , value: msg.value }} />
            );
          }

          return null;
        })}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ResponseBox;
