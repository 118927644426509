import {
  CameraIcon,
  ChatBubbleBottomCenterIcon,
  GlobeAltIcon,
  PhoneIcon,
  TvIcon,
  BeakerIcon,
  QrCodeIcon
} from "@heroicons/react/24/outline";
import { IconButton } from "@material-tailwind/react";

export const SideNav = () => {
  return (
    <div className="absolute right-4 top-1/2 -translate-y-1/2">
      <nav>
        <ul className="flex flex-col">
          <li>
            <IconButton variant="text">
              <QrCodeIcon className="h-4 w-4" />
            </IconButton>
          </li>
          <li>
            <IconButton variant="text">
              <BeakerIcon className="h-4 w-4" />
            </IconButton>
          </li>
        <li>
            <IconButton variant="text">
              <GlobeAltIcon className="h-4 w-4" />
            </IconButton>
          </li>
          <li>
            <IconButton variant="text">
              <CameraIcon className="h-4 w-4" />
            </IconButton>
          </li>
          <li>
            <IconButton variant="text">
              <ChatBubbleBottomCenterIcon className="h-4 w-4" />
            </IconButton>
          </li>
          <li>
            <IconButton variant="text">
              <TvIcon className="h-4 w-4" />
            </IconButton>
          </li>
          <li>
            <IconButton variant="text">
              <PhoneIcon className="h-4 w-4" />
            </IconButton>
          </li>
        </ul>
      </nav>
    </div>
  );
};
