import ReactAudioPlayer from 'react-audio-player';

export const Circle = ({message}) => {
  return (
    <div className="rounded-md text-sm px-4 pb-1 font-sans">
      <div className="flex items-center">
      <span className="whitespace-nowrap w-24 text-gray-500 pr-8">{message.timestamp}</span>
          <ReactAudioPlayer
              src={message.content}
              autoPlay
              controls
          />
      </div>      
    </div>
  );
};
