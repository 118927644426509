import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import socketClusterClient from "socketcluster-client";
import {EventEmitter} from "events";
import Cookies from 'js-cookie';


// Create a context for the backend
const BackendContext = createContext();

// Create an event emitter instance
const eventEmitter = new EventEmitter();

// Custom hook to use the backend context
export const useBackend = () => {
  return useContext(BackendContext);
};

// Provider component that wraps your app and makes the backend available to any child component that calls useBackend().
export const BackendProvider = ({ children }) => {
  // State to hold the socket instance
  const [socket, setSocket] = useState(null);
  // State to hold global state related to the backend
  const [globalState, setGlobalState] = useState({
    messages: [],
  });

  // Additional state data for listening components
  const [currentChannel, setCurrentChannel] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentAuth, setCurrentAuth] = useState(null);
  const [currentLatency, setCurrentLatency] = useState(null);


  // useEffect to initialize the socket connection when the component mounts
  useEffect(() => {
    const local = {
      hostname: '127.0.0.1',
      secure: false,
      port: 8000,
      rejectUnauthorized: false
    };

    // This works but breaks on ping delay // TODO: move to .env / webpack
    const prod = {
      hostname: 'api.anomyze.network',
      secure: true,
      port: 443,
      rejectUnauthorized: false
    };

    // Create the socket connection
    let socket;
    // const socket = socketClusterClient.create(prod); // Uncomment for prod

    // Set the socket in state

    const authToken = Cookies.get('anomyzeToken')!==null ? Cookies.get('anomyzeToken') : null;

    if(authToken){
      //only if the cookie exists do we even try to connect...
      socket = socketClusterClient.create(prod);
      setSocket(socket);
      // console.log('login cookie detected - attempting to connect');
      // On Connection
      (async () => {
        for await (let event of socket.listener("connect")) {
          console.log("Successfully connected and authenticated to the Anomyze Servers");
          setCurrentStatus("connected");

          // we trigger authentication
          const handleAuth = (message) => {
            console.log('Received Authentication Response:', message);
            // setGlobalState((prevState) => ({
            //   ...prevState,
            //   messages: [...prevState.messages, message]
            // }));
          };

          // if(socket.authState!=="authenticated"){
          //   console.log("current socket has no auth state");
          //   console.log(socket.authState);
          // }



          if(socket.authState==='unauthenticated'){
            //we search for the cookie
            if(authToken){
              // console.log('authenticating with token : ' + authToken);
              console.log('authenticating with token');
              const authResult = await socket.invoke("authenticate", authToken);
              if (authResult === "success") {
                console.log("Authentication success");
                console.log(authResult);
                setCurrentAuth(authResult);
                // if (callback) callback(authResult);
                return true;
              } else {
                console.log("Authentication failed");
                // if (callback) callback(false);
                return false;
              }
            } else {
              console.log('no cookie detected - not authenticating to scc');
            }



          } else {
            if(!authToken){
              console.log('cookie deleted - deauthenticating');
              const authResult = await socket.invoke("deauthenticate");
              setCurrentStatus('disconnected');
            } else {
              // console.log("[already authenticated & cookie exists ] socket auth state is: " +socket.authState);
            }
            // const authToken = Cookies.get('anomyzeToken')!==null ? Cookies.get('anomyzeToken') : null;
            // console.log('if no cookie, we will sign them out for now');
            // if(!authToken){
            //   socket.authState = null;
            // }
          }

        }
      })();

      // On Error
      (async () => {
        for await (let { error } of socket.listener("error")) {
          console.log("Error occurred - connection lost");
          setCurrentStatus("disconnected");
        }
      })();


    } else {
      console.log('not logged in - not connecting - not subscribing -  not pinging');
    }




    //troubleshooting
    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
    }



    return () => {
      socket.disconnect();
    };
  }, []);

  // Function to subscribe to a channel and listen for messages
  const subscribeToChannel = useCallback(
    (channelName, callback) => {
      if (socket && currentStatus === "connected") {
        (async () => {
          // Unsubscribe from the current channel
          if (currentChannel) {
            socket.unsubscribe(currentChannel);
            notifyUserLeftChannel(currentChannel);
          }

          // Clear the messages
          clearMessages();

          // Subscribe to the new channel
          if(socket.authState==='unauthenticated'){
            console.log('no auth state, should not subscribe: ' + socket.authState);
          } else if (socket.authState==='authenticated'){
            const channel = socket.subscribe(channelName);
            setCurrentChannel(channelName);


            // Notify the user that they have joined the channel
            notifyUserJoinedChannel(channelName);

            // Use an async iterator to listen for messages on the channel
            for await (let data of channel) {
              const incomingMessage = {
                ...JSON.parse(data),
                time: new Date().toLocaleTimeString(),
              };

              //todo: Move or change from message type to something else - leaving here as an example (used by VideoStuff as trying to set up a video receive event / render) - not a message one)
              eventEmitter.emit("action", data);// This event emitter can be tied to by anyone, and it provides internal event handling (any component may bind, no need for callback)
              //todo: This is technically a smart place to route.. for example - text message should go callback, but emit should give a richer event.
              //todo: Example - we don't need to send back the text message event if we want to auto render something upon receive (on the page) which is done through emission.
              //todo: This way we can leave the "incoming message prints" to their types, and we can also define "emitted events" which can render by other components etc.

              callback(incomingMessage); // Call the provided callback with the received data
            }
          }

        })();

        // Return a function to unsubscribe from the channel
        return () => {
          socket.unsubscribe(channelName);
        };
      }
    },
    [socket, currentStatus, currentChannel]
  );

  // Notify user that they have joined a channel
  const notifyUserJoinedChannel = (channelName) => {
    const currentAction = buildUpdateAction('You have joined channel', channelName);
    setGlobalState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, currentAction],
    }));
  };

  const notifyUserDisconnected = (channelName) => {
    const currentAction = buildUpdateAction('You have been disconnected from the channel', channelName);
    setGlobalState((prevState) => ({
      ...prevState,
      messages: [[], currentAction],
    }));
  };

  // Notify user that they have left a channel
  const notifyUserLeftChannel = (channelName) => {
    const currentAction = buildUpdateAction('You have left channel', channelName);
    setGlobalState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, currentAction],
    }));
  };

  // Build a message action
  const buildAction = (newMessage, channel) => {
    return {
      type: "message",
      text: newMessage,
      channel: channel,
    };
  };

  // Build an update action
  const buildUpdateAction = (newMessage, channel) => {
    return {
      type: "update",
      text: newMessage,
      value: channel,
      time: new Date().toLocaleTimeString(),
    };
  };

  // Clear messages
  const clearMessages = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      messages: [],
    }));
  };

  // Function to send a message
  const sendMessage = useCallback(
    (message, callback) => {
      if (socket && currentStatus === "connected") {
        (async () => {
          const currentAction = buildAction(message, currentChannel);
          await socket.transmitPublish(currentChannel, JSON.stringify(currentAction));
          if (callback) callback();
        })();

        // No need to return anything here
        return () => {};
      }
    },
    [socket, currentStatus, currentChannel]
  );

  // Function to measure ping
  const measurePing = useCallback(
    (payload, callback) => {
      if (socket && currentStatus === "connected") {
        (async () => {
          const start = Date.now();
          try {
            const pingResult = await socket.invoke('ping', { id: socket.id, currentChannel, eventType:'ping' });
            if (pingResult === 'pong') {
              const end = Date.now();
              const ping = end - start;
              setCurrentLatency(ping);
              // console.log('ping');
              if (callback) callback(true);
              return true;
            }
          } catch (e) {
            console.log('ping failed - setting to disconnected and stopping pings');
            setCurrentStatus('disconnected');
            // Let it fail passively without changing the status
          }
          if (callback) callback(false);
          return false;
        })();

        // No need to return anything here
        return () => {};
      }
    },
    [socket, currentStatus, currentChannel]
  );

  // Function to authenticate to the server
  // const authenticateToServer = useCallback(
  //   (payload, callback) => {
  //
  //     console.log('global socket is : ' + socket);
  //     console.log('current global status is : ' + currentStatus);
  //
  //     if (socket && currentStatus === "connected") {
  //       (async () => {
  //         console.log(`Triggering authentication with payload: [${payload}]`);
  //         const authResult = await socket.invoke("authenticate", { payload });
  //         setCurrentAuth(authResult);
  //         if (authResult === "success") {
  //           console.log("Authentication success");
  //           if (callback) callback(authResult);
  //           return true;
  //         } else {
  //           console.log("Authentication failed");
  //           if (callback) callback(false);
  //           return false;
  //         }
  //       })();
  //
  //       // No need to return anything here
  //       return () => {};
  //     } else {
  //       console.log('bad logic');
  //     }
  //   },
  //   [socket, currentStatus]
  // );

  return (
    <BackendContext.Provider
      value={{
        socket,
        globalState,
        setGlobalState,
        subscribeToChannel,
        currentChannel,
        setCurrentChannel,
        currentStatus,
        // authenticateToServer,
        currentAuth,
        setCurrentAuth,
        sendMessage,
        measurePing,
        currentLatency,
        setCurrentLatency,
        eventEmitter
      }}
    >
      {children}
    </BackendContext.Provider>
  );
};
