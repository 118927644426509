import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";
import {Register} from "./Register";
import {SignIn} from "./SignIn";

export function AuthModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  // TODO: get current user state and render the correct form

  const [showUserSignIn, setShowUserSignIn] = useState(true);

  return (
    <>
      <Button onClick={handleOpen} variant="gradient" className="whitespace-nowrap">
        Sign In
      </Button>
      <Dialog open={open} handler={handleOpen} size={"xs"}>
        <DialogBody>
          {showUserSignIn ? (
            <SignIn setShowUserSignIn={setShowUserSignIn} />
          ):(
            <Register setShowUserSignIn={setShowUserSignIn} />
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}