import React, { useEffect } from "react";
import ResponseBox from "./ResponseBox";
import { ChatInput } from "./ChatInput";
export const Chat = () => {


  return (
    <div className="flex flex-col h-full">
      <ResponseBox />
      <ChatInput
        sendMessage={() => {
          return null;
        }}
        channel={"test1"}
      />
    </div>
  );
};
