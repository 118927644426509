import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import {useState} from "react";
import {AUTH_SERVER_URL} from "./Register";

export function SignIn({setShowUserSignIn}) {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);


  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer your-auth-token', // example header
      },
      body: JSON.stringify(formData),
      credentials: "include", //this fixes underlying CORS issues (without this, cookies don't get set locally even if response is correct)
    };

    try {
      const response = await fetch(`${AUTH_SERVER_URL}/login`, requestOptions);
      const data = await response.json();
      console.log('Login Submitted:', data);
      if(data.data.status==='success'){
        window.location.reload();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  return (
    <Card color="transparent" shadow={false}>
      <Typography variant="h4" color="blue-gray">
        Sign in
      </Typography>
      <Typography color="gray" className="mt-1 font-normal">
        Nice to meet you! Enter your details to register.
      </Typography>
      <form className="mt-8 mb-2 w-auto" onSubmit={handleSubmit}>
        <div className="mb-1 flex flex-col gap-6">
          <div className="mb-6">
            <label htmlFor="email">
              <Typography
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Your Email
              </Typography>
            </label>
            <Input
              id="email"
              color="gray"
              size="lg"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="name@mail.com"
              className="w-full placeholder:opacity-100 !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "hidden before:content-none after:content-none",
              }}
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password">
              <Typography
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Password
              </Typography>
            </label>
            <Input
              size="lg"
              placeholder="********"
              labelProps={{
                className: "hidden before:content-none after:content-none",
              }}
              name="password"
              className="w-full placeholder:opacity-100 !border-t-blue-gray-200 focus:!border-t-gray-900"
              type={passwordShown ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              icon={
                <i onClick={togglePasswordVisiblity}>
                  {passwordShown ? (
                    <EyeIcon className="h-5 w-5" />
                  ) : (
                    <EyeSlashIcon className="h-5 w-5" />
                  )}
                </i>
              }
            />
          </div>
        </div>
        <Button className="mt-6" fullWidth type={"submit"}>
          sign in
        </Button>
        <Typography color="gray" className="mt-4 text-center font-normal">
          Need an account?{" "}
          <a href="#" className="font-medium text-gray-900" onClick={()=>setShowUserSignIn(false)}>
            Sign up
          </a>
        </Typography>
      </form>
    </Card>
  );
}