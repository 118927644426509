import React, { useEffect, useState } from "react";
import { useBackend } from "./../../context/BackendContext";

const ActionEmitter = () => {
    // Use local state to manage received messages
    const [actionItems, setActionItems] = useState([]);
    const { eventEmitter } = useBackend();

    //This allows us to push items to the actionItemsState from the event handler
    function pushIncomingActionState(incomingAction){
      setActionItems((prevActionItems) => [...prevActionItems, incomingAction ]);
    }

    useEffect(() => {
        const handleAction = (videoMessage) => {
            // console.log("Received message:", videoMessage);
            // console.log("Received action:", JSON.parse(videoMessage).type);
            let incomingAction = JSON.parse(videoMessage); //we map the event emitted to a proper action

           //todo: commenting out just for now...
           //  switch (incomingAction.type) {
           //    case 'message': console.log('ActionEmitter: message, ignoring');break;
           //    case 'video': console.log('ActionEmitter: for testing non default');break;
           //    default: {
           //      console.log('default incomingAction');
           //      pushIncomingActionState(incomingAction);
           //      break;
           //    }
           // }
        };

        // Bind the event handler to the "message" event
        eventEmitter.on("action", handleAction);

        // Cleanup the event handler on unmount
        return () => {
            eventEmitter.off("action", handleAction);
        };
    }, [eventEmitter]);

    return (
      <div>
          {/*<h1>This is an event receiver</h1>*/}
          {/* Render received messages */}
          <ul>
              {actionItems.map((actionItems, index) => (
                <li key={index}>{actionItems.text}</li>
              ))}
          </ul>
      </div>
    );
};

export default ActionEmitter;
