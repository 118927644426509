import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
} from "@material-tailwind/react";
import {
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  ArrowRightStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import {ConnectionStatus} from "../ConnectionStatus";
import {LatencyStatus} from "../LatencyStatus";
import {ChannelInput} from "../ChannelInput";
import {AuthModal} from "../Auth/AuthModal";
import {useBackend} from "../../context/BackendContext";
import {MoonIcon, SunIcon} from "@heroicons/react/16/solid";

function NavList() {

  const [darkMode, setDarkMode] = React.useState(document.documentElement.classList.contains('dark'));
  const {
    currentStatus
  } = useBackend();

  const clearAuthCookie = () => {
    document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "anomyzeToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
  }

  const toggleDarkMode = () => {
    if (darkMode) {
      document.documentElement.classList.remove('dark');
      setDarkMode(false);
    }  else {
      document.documentElement.classList.add('dark');
      setDarkMode(true);
    }
  }

  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 min-w-0">
      <Typography
        as="a"
        href="#"
        variant="small"
        color="blue-gray"
        className="font-medium text-gray-900 dark:text-white"
      >
        <ListItem className="flex items-center gap-2 p-2">
          <UserCircleIcon className="h-6 w-6" strokeWidth={1} />
        </ListItem>
      </Typography>
      {!currentStatus ?
        (<AuthModal />)
        :
        (<Typography
          onClick={clearAuthCookie}
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-medium text-gray-900 dark:text-white"
        >
          <ListItem className="flex items-center gap-2 p-2">
            <ArrowRightStartOnRectangleIcon className="h-6 w-6" strokeWidth={1} />
          </ListItem>
        </Typography>)
      }
      <Typography
        as="a"
        href="#"
        variant="small"
        color="blue-gray"
        className="font-medium text-gray-900 dark:text-white"
        onClick={toggleDarkMode}
      >
        <ListItem className="flex items-center gap-2 p-2">
          { darkMode ? <SunIcon className="h-6 w-6" strokeWidth={1} /> : <MoonIcon className="h-6 w-6" strokeWidth={1} /> }
        </ListItem>
      </Typography>





    </List>
  );
}

export function Navigation() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <Navbar fullWidth={true} color="transparent" className=" px-4 border-b dark:border-gray-800 py-2">
      <div className="flex items-center justify-between text-blue-gray-900">
        <div className="flex items-center">
          <Typography
            as="a"
            href="#"
            variant="h6"
            className="mr-4 cursor-pointer py-1.5 lg:ml-2 text-gray-900 dark:text-white"
          >
            Anomyze
          </Typography>
          <ConnectionStatus />
          <LatencyStatus/>
        </div>
        <div className="flex items-center shrink-0">
          <ChannelInput />
          <div className="hidden lg:block">
            <NavList />
          </div>
          <IconButton
            variant="text"
            color="blue-gray"
            className="lg:hidden text-gray-900 dark:text-white"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3Icon className="h-6 w-6" />
            )}
          </IconButton>
        </div>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
}
