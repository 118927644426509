import React from "react";
import { useBackend } from "../../context/BackendContext";

export const ConnectionStatus = () => {
    const {
        currentChannel,
        currentStatus
    } = useBackend();

    if (currentStatus === 'connected' ) {
        return (
          <div className="text-sm text-green-500">{currentChannel ? currentChannel : 'Connected' }</div>
        )
    } else {
        return (
          <div className="text-sm text-red-500">Disconnected</div>
        )
    }
};
