import React, {useState,useEffect} from "react";
import { useBackend } from "../../context/BackendContext";
import {Input} from "@material-tailwind/react";
import {GlobeAltIcon} from "@heroicons/react/24/outline";

export const ChannelInput = () => {
  const {
    subscribeToChannel, setGlobalState, authenticateToServer, currentChannel,currentStatus,currentAuth
  } = useBackend();

  const [channelInput, setChannelInput] = useState("")

  //Adding default case to join anomyze.com
  useEffect(() => {
    if(currentChannel===null && currentStatus==='connected'){
      subscribeAndListenForMessage('anomyze.com');
    }
  }, [currentStatus]); // Dependencies: currentStatus, measurePing

  function subscribeAndListenForMessage(channel) {
    const handleMessage = (message) => {
      // console.log("Received message:", message);
      setGlobalState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    };

    if(currentStatus==='connected'){
      const unsubscribe = subscribeToChannel(channel, handleMessage);
    } else {
      console.log('not connected - not subscribing');
    }

    // return () => {
    //   if (unsubscribe) unsubscribe();
    // };
  }

  //Finally a useful RPC call - others can be defined in this format.
  function triggerAuthenticate(payload) {
    const handleAuthenticationResponse = (response) => {
      console.log("Authenticate response: " + response);
      //todo: this would work, but we don't need to set this here - its done at the BackendContext
      //setCurrentAuth(response);
      //however, we can trigger the following event after this is successful.
    };

    authenticateToServer(payload, handleAuthenticationResponse);
    return () => {
      //Do we need a return object?
    };
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (channelInput) {
        subscribeAndListenForMessage(channelInput);
        setChannelInput("");
      }
    }
  };


 return (
   <div className="flex text-gray-900 dark:text-white">
     <Input onKeyDown={handleKeyPress}
            className="!border-t-blue-gray-200 focus:!border-t-gray-900 mr-4 text-gray-900 dark:text-white"
            value={channelInput}
            onChange={(e) => setChannelInput(e.target.value) }
            placeholder={"Enter channel..."}
            icon={<GlobeAltIcon className="h-4 w-4 text-gray-900 dark:text-white" />}
            labelProps={{
             className: "before:content-none after:content-none text-gray-900 dark:text-white",
            }}

     />
   </div>
 )
};
