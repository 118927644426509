import React, { useEffect } from "react";
import { useBackend } from "../../context/BackendContext";


const PING_REFRESH_RATE = 5000;
export const LatencyStatus = () => {
    const {
        measurePing,
        currentLatency,
        currentStatus,
    } = useBackend();

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentStatus === 'connected') {
                measurePing();
            }
        }, PING_REFRESH_RATE);

        return () => clearInterval(interval); // Cleanup interval on unmount or dependency change
    }, [currentStatus, measurePing]); // Dependencies: currentStatus, measurePing

    //Arbitrary Values
    const getPingStyle = (ping) => {
        if (ping < 50) {
            return 'text-green-500';
        } else if (ping < 100) {
            return 'text-yellow-500';
        } else if (ping < 200) {
            return 'text-orange-500';
        } else {
            return 'text-red-500';
        }
    };

    if(currentStatus==='connected' && currentLatency!=null){
        return (
          <div className={`text-sm pl-2 ${getPingStyle(currentLatency)}`}>{currentLatency} ms</div>
        )
    } else {
        return (
          <div className="text-sm text-red-500 pl-2"></div>
        )
    }

};
