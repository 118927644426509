import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import {useBackend} from "../../../context/BackendContext";

export const ChatInput = () => {
  const { sendMessage } = useBackend();
  const [message, setInput] = useState("");
  const [empty, setEmpty] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") { //disallowed sending empty message
      if(message===""){
        setEmpty('Please Type Here')
      } else {
        setEmpty(null)
        sendMessage(message);
        setInput("");
      }

    }
  };

  return (
    <div className="border-t dark:border-gray-800 flex py-2 px-4 items-center">
      <ChevronRightIcon className="h-4 w-4 text-blue-gray-500 mr-1" />
      <input
        type="text"
        className="text-gray-900 dark:text-white bg-white dark:bg-gray-900 pt-1 pb-1 pl-2 !border-b-0 !border-0 focus:border-b-0 w-full outline-none text-blue-gray-700 font-sans font-normal text-sm"
        value={message}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder={`${empty ? empty : 'Type message here...'}`}
      />
    </div>
  );
};
