import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import {useState} from "react";

export const AUTH_SERVER_URL = 'https://api.anomyze.network'

export function Register({setShowUserSignIn}) {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    termsAndConditions: false, // Initialize as a boolean
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value // Handle checkbox change
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.termsAndConditions) {
      alert('You must agree to the terms and conditions');
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer your-auth-token', // example header
      },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(`${AUTH_SERVER_URL}/register`, requestOptions);
      const data = await response.json();
      console.log('Form data submitted:', data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Card color="transparent" shadow={false}>
      <Typography variant="h4" color="blue-gray">
        Sign Up
      </Typography>
      <Typography color="gray" className="mt-1 font-normal">
        Nice to meet you! Enter your details to register.
      </Typography>
      <form className="mt-8 mb-2 w-auto" onSubmit={handleSubmit}>
        <div className="mb-1 flex flex-col gap-6">
          <div className="mb-6">
            <label htmlFor="email">
              <Typography
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Your Email
              </Typography>
            </label>
            <Input
              id="email"
              color="gray"
              size="lg"
              type="email"
              name="email"
              placeholder="name@mail.com"
              className="w-full placeholder:opacity-100 !border-t-blue-gray-200 focus:!border-t-gray-900"
              value={formData.email}
              onChange={handleChange}
              labelProps={{
                className: "hidden before:content-none after:content-none",
              }}
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password">
              <Typography
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Password
              </Typography>
            </label>
            <Input
              size="lg"
              placeholder="********"
              labelProps={{
                className: "hidden before:content-none after:content-none",
              }}
              name="password"
              id="password"
              className="w-full placeholder:opacity-100 !border-t-blue-gray-200 focus:!border-t-gray-900"
              type={passwordShown ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              icon={
                <i onClick={togglePasswordVisiblity}>
                  {passwordShown ? (
                    <EyeIcon className="h-5 w-5" />
                  ) : (
                    <EyeSlashIcon className="h-5 w-5" />
                  )}
                </i>
              }
            />

          </div>
        </div>
        <Checkbox
          id="termsAndConditions"
          name="termsAndConditions"
          checked={formData.termsAndConditions}
          onChange={handleChange}
          label={
            <Typography
              id="terms-and-conditions"
              variant="small"
              color="gray"
              className="flex items-center font-normal"
            >
              I agree the
              <a
                href="#"
                className="font-medium transition-colors hover:text-gray-900"
              >
                &nbsp;Terms and Conditions
              </a>
            </Typography>
          }
          containerProps={{ className: "-ml-2.5" }}
        />
        <Button className="mt-6" fullWidth type="submit">
          sign up
        </Button>
        <Typography color="gray" className="mt-4 text-center font-normal">
          Already have an account?{" "}
          <a href="#" className="font-medium text-gray-900" onClick={()=>setShowUserSignIn(true)}>
            Sign in
          </a>
        </Typography>
      </form>
    </Card>
  );
}