import './App.css';
import { Navigation } from './components/Navigation/index.js';
import { SideNav } from './components/SideNav/index.js';
import { Chat } from './components/Chat/index.js';
import { ReactMediaRecorder } from "react-media-recorder";
import ActionEmitter from "./components/TestComponent/ActionEmitter";
import {useEffect} from "react";
// import SomeComponent from "./components/TestComponent/SomeComponent";
// import AnotherComponent from "./components/TestComponent/AnotherComponent";

function App() {

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  return (
    <div className="App flex flex-col h-full bg-white dark:bg-gray-900 dark:text-white">
      <ActionEmitter/>
      <Navigation />
      <SideNav />
      <div className="flex-grow min-h-20 h-full">
        <Chat />
      </div>
        {/* <SomeComponent/>
        <AnotherComponent/> */}
    </div>
  );
}

export default App;
